<template>
  <div v-show="hasApiDefinition" class="flex justify-start">
    <div class="flex-1">
      <div class="swagger" id="swagger"></div>
    </div>
    <div class="flex-none mt-4 mr-7">
      <button
        ref="copybtn"
        class="btn"
        @click="handleCopyToClipboard"
        @mouseleave="removeFocus"
      >
        Copy AccessToken
      </button>
      <div>
        <p>Access token expires in:<br />{{ formattedRemainingTime }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import SwaggerUI from "swagger-ui";
import "swagger-ui/dist/swagger-ui.css";
import msalService from "@/_auth/msalService";
import { useStore } from "vuex";
import { computed, onMounted, watchEffect, ref } from "vue";
import copyToClipboard from "@/_utils/CopyToClipboard";
import SwaggerSerice from "@/_services/SwaggerService";

export default {
  name: "SwaggerComponent",
  setup() {
    const store = useStore();
    const accessTokenCopied = ref(false);
    const swaggerService = SwaggerSerice();
    const timeRemaining = computed(() => {
      return store.getters.getRemainingTimeToken;
    });
    const remainingTime = ref(0);

    const tokenExpiresOn = computed(() => {
      return store.getters.getTokenExp;
    });

    const hasValidToken = computed(() => {
      return store.getters.getIsTokenValid;
    });

    const hasApiDefinition = computed(() => {
      return Object.keys(store.getters.getApiDefinition.length > 0);
    });

    const copybtn = ref(null);

    const removeFocus = () => {
      setTimeout(() => {
        copybtn.value.blur();
      }, 50);
    };

    const startCountDown = () => {
      console.log("starting countdown");

      remainingTime.value = timeRemaining.value;

      setInterval(() => {
        remainingTime.value--;

        if (remainingTime.value < 100) {
          console.log("renewing token");
          msalService.getToken();
        }
      }, 1000);
    };

    const formattedRemainingTime = computed(() => {
      const minutes = Math.floor(remainingTime.value / 60);
      const seconds = remainingTime.value % 60;
      return `${minutes} minutes ${seconds} seconds`;
    });

    const handleCopyToClipboard = () => {
      if (!hasValidToken.value) {
        msalService.getToken();
      }
      const accessToken = "Bearer " + store.getters.getAccessToken;
      copyToClipboard(accessToken);
      accessTokenCopied.value = true;
    };

    onMounted(() => {
      swaggerService.SWAGGER_DOCS();
      startCountDown();
      watchEffect(() => {
        const api_definition = store.getters.getApiDefinition;
        if (api_definition) {
          SwaggerUI({
            spec: api_definition,
            dom_id: "#swagger"
          });
        }
      });
    });

    return {
      hasValidToken,
      hasApiDefinition,
      handleCopyToClipboard,
      accessTokenCopied,
      tokenExpiresOn,
      timeRemaining,
      formattedRemainingTime,
      copybtn,
      removeFocus
    };
  }
};
</script>

<style></style>
